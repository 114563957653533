import React, { useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import "./App.css";
import Home from "./pages/Home";
import MainGame from "./pages/MainGame";
import Profile from "./pages/Profile";
import ProtectedRouter from "./auth/ProtectedRouter";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import DataContext from "./context/DataContext";
import Game from "./pages/Game";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
}

declare global {
  interface Window {
    Telegram: any; // Replace 'any' with the actual type if you know it
  }
}
const secretKey: string = String(process.env.REACT_APP_TRIP_DES_KEY);
const socket_url: string = String(process.env.REACT_APP_SOCKET_URL);
const tele = window.Telegram.WebApp;

const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRouter />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/game", element: <MainGame /> },
      { path: "/game/play", element: <Game /> },
      { path: "/profile", element: <Profile /> },
    ],
  },
]);

function App() {
  const [user, setUser] = useState(null);
  const [botConnection, setBotConnection] = useState<WebSocket | null>(null);
  const [dataServer, setDataServer] = useState(null);
  const [dataTele, setDataTele] = useState(null);

  useEffect(() => {
    console.log("tele", tele);
    tele.ready();
    try {
      const initData = tele.initDataUnsafe; // Use Telegram.WebApp.initData in production
      if (initData) {
        setDataTele(initData);
      }
      console.log("initData", initData);
    } catch (error) {
      const abc: any = {
        query_id: "AAH6jMNNAAAAAPqMw02LAE24",
        user: {
          id: 1304661242,
          first_name: "Phong",
          last_name: "Tran",
          username: "nhiepphong",
          language_code: "en",
          allows_write_to_pm: true,
        },
        auth_date: "1715263464",
        hash: "792a2fb9119b96de386587dd6972ca4b0d4b03705a46ef81cc32d4ca48831418",
      };
      setDataTele(abc);
      console.error("Error getting initData:", error);
    }

    // Get the session ID from the URL
  }, []);

  useEffect(() => {
    if (dataTele) {
      const sessionId = new URLSearchParams(window.location.search).get(
        "sessionId"
      );
      console.log("sessionId", sessionId);
      // Connect to the Node.js bot using the session ID
      const bot = new WebSocket(`${socket_url}?sessionId=${sessionId}`);

      bot.onopen = () => {
        console.log("WebSocket connection established");
        setBotConnection(bot);
      };

      bot.onmessage = (event) => {
        const decryptedData = CryptoJS.AES.decrypt(
          event.data,
          secretKey
        ).toString(CryptoJS.enc.Utf8);
        const data = JSON.parse(decryptedData);
        console.log("Received message:", data);
        if (data.action == "init") {
          setUser(data.user);
        } else {
          setDataServer(data.data);
        }
      };

      bot.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      bot.onclose = () => {
        console.log("WebSocket connection closed");
      };
    }
  }, [dataTele]);

  const sendMessage = (action: string, data: any) => {
    if (botConnection) {
      const encrypted = CryptoJS.AES.encrypt(
        JSON.stringify({ action: action, data: data }),
        secretKey
      ).toString();
      botConnection.send(encrypted);
      console.log("Message sent:", encrypted);
    }
  };

  return (
    <DataContext.Provider
      value={{
        user,
        setUser,
        dataServer,
        setDataServer,
        sendMessage,
      }}
    >
      <div className="w-full h-full h-screen">
        <RouterProvider router={router} />
      </div>
    </DataContext.Provider>
  );
}

export default App;
