import React, { Fragment, useContext, useEffect, useState } from "react";
import DataContext from "../context/DataContext";
import banner from "../assets/banner.png";

function Home() {
  const [message, setMessage] = useState("");
  const { user, sendMessage, dataServer } = useContext(DataContext)!;

  useEffect(() => {
    console.log("user", user);
  }, [user]);

  useEffect(() => {
    console.log("dataServer", dataServer);
  }, [dataServer]);

  const sendMessageToServer = () => {
    if (message.trim() === "") return;
    sendMessage("message", { message });
  };

  return (
    <div className="flex flex-col">
      {/* <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
        <h1 className="text-2xl font-bold mb-4">Telegram Web App</h1>
        <div className="mb-4">
          <p>Bot message: {user ? user.teleID : "--"}</p>
        </div>
        <div className="flex">
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="border border-gray-300 rounded-l-lg py-2 px-4 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Type your message..."
          />
          <button
            onClick={sendMessageToServer}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-r-lg"
          >
            Send
          </button>
        </div>
      </div> */}
      <div className="w-full aspect-[8/3] bg-blue-400">
        <img src={banner} className="w-full h-full object-cover" />
      </div>
      <div className="mt-[20px]">
        <p className="text-[18px] font-bold text-center text-white mb-[20px]">
          History
        </p>
        <div className="w-full grid grid-cols-5 px-[12px]">
          <div className="w-full px-[8px] py-[4px] bg-[#e17202] bg-opacity-80 rounded-tl-[4px] border-r border-solid border-gray-500">
            <p className="text-[12px] font-semibold text-black text-center">
              Telegram
            </p>
          </div>
          <div className="w-full px-[8px] py-[4px] bg-[#e17202] bg-opacity-80 border-r border-solid border-gray-500">
            <p className="text-[12px] font-semibold text-black text-center">
              Amount
            </p>
          </div>
          <div className="w-full px-[8px] py-[4px] bg-[#e17202] bg-opacity-80 border-r border-solid border-gray-500">
            <p className="text-[12px] font-semibold text-black text-center">
              Number
            </p>
          </div>
          <div className="w-full px-[8px] py-[4px] bg-[#e17202] bg-opacity-80 border-r border-solid border-gray-500">
            <p className="text-[12px] font-semibold text-black text-center">
              Predict
            </p>
          </div>
          <div className="w-full px-[8px] py-[4px] bg-[#e17202] bg-opacity-80 rounded-tr-[4px]">
            <p className="text-[12px] font-semibold text-black text-center">
              Result
            </p>
          </div>
          {[1, 2, 3, 4].map((item) => (
            <Fragment key={item}>
              <div className="w-full px-[8px] py-[4px] bg-[#1a110f] bg-opacity-80 border-r border-b border-solid border-gray-500">
                <p className="text-[12px] font-semibold text-black truncate text-white">
                  Phong
                </p>
              </div>
              <div className="w-full px-[8px] py-[4px] bg-[#1a110f] bg-opacity-80 border-r border-b border-solid border-gray-500">
                <p className="text-[12px] font-semibold text-black truncate text-white">
                  3323
                </p>
              </div>
              <div className="w-full px-[8px] py-[4px] bg-[#1a110f] bg-opacity-80 border-r border-b border-solid border-gray-500">
                <p className="text-[12px] font-semibold text-black truncate text-white">
                  333223mi
                </p>
              </div>
              <div className="w-full px-[8px] py-[4px] bg-[#1a110f] bg-opacity-80 border-r border-b border-solid border-gray-500">
                <p className="text-[12px] font-semibold text-black truncate text-white">
                  Đang hoạt động
                </p>
              </div>
              <div className="w-full px-[8px] py-[4px] bg-[#1a110f] bg-opacity-80 border-b border-solid border-gray-500">
                <p className="text-[12px] font-semibold text-black truncate text-white">
                  Đang hoạt động
                </p>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Home;
