import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Game from "../assets/game.png";

function MainGame() {
  const navigate = useNavigate();
  const handleJoin = () => {
    navigate("/game/play");
  };
  useEffect(() => {}, []);

  return (
    <>
      <div className="w-full flex-col flex p-[16px]">
        <div className="w-full flex flex-col justify-between items-center relative">
          <div className="w-full mx-auto overflow-hidden ">
            <img
              src={Game}
              className="w-full h-auto rounded-[8px] border-solid border border-[#52c0ff]"
            />
          </div>
          <div className=" mt-[-40px] left-0 flex justify-center">
            <Button handleClick={handleJoin} label="Tham gia ngay" />
          </div>
        </div>
      </div>
      <div className="w-full flex-col flex p-[16px]">
        <div className="w-full flex flex-col justify-between items-center rounded-[8px] relative">
          <div className="w-full mx-auto overflow-hidden ">
            <img
              src={Game}
              className="w-full h-auto rounded-[8px] border-solid border border-[#52c0ff]"
            />
          </div>
          <div className=" mt-[-40px] left-0 flex justify-center">
            <Button handleClick={handleJoin} label="Tham gia ngay" />
          </div>
        </div>
      </div>
    </>
  );
}

export default MainGame;
