import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Avatar from "../assets/avatar.png";
import CustomInput from "../components/Input";
import GameBG from "../assets/game.png";
function Game() {
  const [bidMoney, setBidMoney] = useState("");
  const [bidMoney2, setBidMoney2] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isWin, setIsWin] = useState(true);
  const handlePlay = () => {
    setIsLoading(true);
    setIsPlaying(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };
  const handleReplay = () => {
    setIsPlaying(false);
  };

  useEffect(() => {}, []);

  return (
    <>
      <div className="w-full flex-col flex p-[16px]">
        <div className="w-full flex flex-col justify-between items-center relative">
          <div className="w-full mx-auto overflow-hidden ">
            <img
              src={GameBG}
              className="w-full h-auto rounded-[8px] border-solid border border-[#52c0ff]"
            />
          </div>
        </div>
      </div>
      <div className="w-full flex-col flex  px-[16px] pb-[20px]">
        <p className="relative text-[24px] text-center font-bold mb-[8px] text-white">
          Appocolus Game
        </p>
        <div className="p-[12px] rounded-[8px] shadow mb-[20px]">
          <p className="relative text-[14px] leading-[20px] mb-[8px] font-semibold text-white">
            How to play
          </p>
          <p className="text-[14px] font-normal text-white leading-[20px]">
            Có rất nhiều thứ cần học hỏi trong LMHT, vì vậy, chúng ta sẽ bắt đầu
            từ những điều cơ bản. Khám phá hướng dẫn dưới đây để biết cách chơi
            chế độ phổ biến nhất.
          </p>
        </div>
        {isPlaying == false && isLoading === false ? (
          <div className="p-[12px] rounded-[8px] shadow mb-[20px]">
            <p className="relative text-[24px] text-center font-bold mb-[8px] text-white">
              Play Now
            </p>
            <p className="text-[12px] text-white font-bold mb-[4px]">
              Amount TON
            </p>
            <CustomInput
              type="number"
              value={bidMoney}
              onChange={(e) => setBidMoney(e.target.value)}
              placeholder="Amount TON"
            />

            <p className="text-[12px] text-white font-bold mb-[4px] mt-[12px]">
              Predict
            </p>
            <CustomInput
              type="text"
              value={bidMoney2}
              onChange={(e) => setBidMoney2(e.target.value)}
              placeholder="Predict"
            />
          </div>
        ) : (
          <></>
        )}

        <div className="flex-1 ">
          {isPlaying && isLoading === false ? (
            <div className="shadow bg-[#000000] bg-opacity-60 p-[16px] rounded-[8px] w-full">
              <p className="text-[14px] text-white truncate font-bold">
                Txhash:{" "}
                <span className="text-[14px] text-white font-semibold">
                  da212132dasddx123121213123
                </span>
              </p>
              <p className="text-[14px] text-white truncate">
                Sequence of digits: <span className="font-semibold">Tài</span>
              </p>
              <p className="text-[14px] text-white truncate">
                Numbers after being added together::{" "}
                <span className="font-semibold">123</span>
              </p>
              <div className="w-full border-t-[2px] border-t-gray-500 my-[4px] " />
              <p className="text-[14px] text-white truncate">
                Amount TON: <span className="font-semibold">Brazzilll</span>
              </p>
              <p className="text-[14px] text-white truncate">
                Your prediction:{" "}
                <span className="font-semibold">Brazzilll</span>
              </p>
              <div
                className={`w-[80px] aspect-square ${
                  isWin ? "bg-green-700" : "bg-red-900"
                } rounded-[16px]  mx-auto my-[20px] flex items-center justify-center`}
              >
                <p className="text-white text-[16px] font-semibold">
                  {isWin ? "Win" : "Lose"}
                </p>
              </div>
              <Button
                isLoading={isLoading}
                label="Play Again"
                handleClick={handleReplay}
              />
            </div>
          ) : (
            <Button
              isLoading={isLoading}
              label="Chơi"
              handleClick={handlePlay}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Game;
