import { Fragment, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import NavBar from "../components/Navbar";
import ScrollToTop from "../components/ScrollTopTop";

export default function Root() {
  return (
    <>
      <div className="w-full max-w-[720px] mx-auto h-full flex flex-col justify-start">
        <div id="" className="flex-1 flex flex-col pb-[56px] ">
          <Outlet />
        </div>
      </div>
      <NavBar />
    </>
  );
}
