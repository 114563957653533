import { url } from "inspector";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import qrocde from "../assets/qrcode.png";
function Profile() {
  const navigate = useNavigate();
  const [walletAddress, setWalletAddress] = useState(
    "0x4512fee3ac0a1ba63e99479f20052b880dfd667c500a215b7c0bca8df5fe0064"
  );
  const [balance, setBalance] = useState(0.084474);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(walletAddress);
  };

  useEffect(() => {}, []);
  return (
    <>
      <div className="w-full flex flex-col   px-[16px] pt-[20px]">
        <p className="text-[20px] mb-[8px] text-center font-semibold text-white font-bold">
          Phong Le Account
        </p>
        <div className="w-2/3 mx-auto mb-[20px] aspect-square rounded-[16px] overflow-hidden ">
          <img
            src={qrocde}
            className="w-full h-auto aspect-square object-cover "
          />
        </div>
        <p className="text-[18px] text-gray-400 text-center mb-[20px] text-white font-bold">
          {balance} <span className="text-blue-700">Ton</span>
        </p>
        <p className="text-[18px] text-gray-400 text-center mb-[20px] text-white font-bold">
          Winner/Play: {balance}
        </p>
        <div className="flex justify-between gap-[8px] bg-white shadow-md border rounded-[4px] px-[12px] py-[8px]">
          <p className="text-[14px] text-gray-500 truncate font-semibold">
            {walletAddress}
          </p>
          <p
            onClick={copyToClipboard}
            className="text-[14px] font-bold cursor-pointer duration-200 active:translate-x-[1px] active:translate-y-[2px]"
          >
            Copy
          </p>
        </div>
        <p className="text-[14px] text-gray-400 text-center mt-[20px] text-white font-bold">
          You can copy this wallet address to deposit more money or scan the
          qrcode above to continue playing.
          <br />
          If you want to withdraw TON to your wallet, please access telegram and
          use the transfer function
        </p>
      </div>
      <div className="mt-[20px]">
        <p className="text-[18px] font-bold text-center text-white mb-[20px]">
          History
        </p>
        <div className="w-full grid grid-cols-4 px-[12px]">
          <div className="w-full px-[8px] py-[4px] bg-[#e17202] bg-opacity-80 border-r border-solid border-gray-500">
            <p className="text-[12px] font-semibold text-black text-center">
              Amount
            </p>
          </div>
          <div className="w-full px-[8px] py-[4px] bg-[#e17202] bg-opacity-80 border-r border-solid border-gray-500">
            <p className="text-[12px] font-semibold text-black text-center">
              Number
            </p>
          </div>
          <div className="w-full px-[8px] py-[4px] bg-[#e17202] bg-opacity-80 border-r border-solid border-gray-500">
            <p className="text-[12px] font-semibold text-black text-center">
              Predict
            </p>
          </div>
          <div className="w-full px-[8px] py-[4px] bg-[#e17202] bg-opacity-80 rounded-tr-[4px]">
            <p className="text-[12px] font-semibold text-black text-center">
              Result
            </p>
          </div>
          {[1, 2, 3, 4].map((item) => (
            <Fragment key={item}>
              <div className="w-full px-[8px] py-[4px] bg-[#1a110f] bg-opacity-80 border-r border-b border-solid border-gray-500">
                <p className="text-[12px] font-semibold text-black truncate text-white">
                  3323
                </p>
              </div>
              <div className="w-full px-[8px] py-[4px] bg-[#1a110f] bg-opacity-80 border-r border-b border-solid border-gray-500">
                <p className="text-[12px] font-semibold text-black truncate text-white">
                  333223mi
                </p>
              </div>
              <div className="w-full px-[8px] py-[4px] bg-[#1a110f] bg-opacity-80 border-r border-b border-solid border-gray-500">
                <p className="text-[12px] font-semibold text-black truncate text-white">
                  Đang hoạt động
                </p>
              </div>
              <div className="w-full px-[8px] py-[4px] bg-[#1a110f] bg-opacity-80 border-b border-solid border-gray-500">
                <p className="text-[12px] font-semibold text-black truncate text-white">
                  Đang hoạt động
                </p>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    </>
  );
}

export default Profile;
