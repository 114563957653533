import { ChangeEvent } from "react";

type Props = {
  placeholder?: string;
  value: string;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
};

const CustomInput = ({
  placeholder,
  value,
  onChange,
  type = "text",
}: Props) => {
  return (
    <div className="w-full ">
      <input
      type={type}
        value={value}
        onChange={onChange}
        className="w-full border-black border px-[8px] py-[4px] rounded-[4px] bg-white "
        placeholder={placeholder}
      />
    </div>
  );
};

export default CustomInput;
