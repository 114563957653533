type Props = {
  label: string;
  handleClick: () => void;
  isLoading?: boolean;
};
const Button = ({ label, handleClick, isLoading = false }: Props) => {
  return (
    <>
      {isLoading ? (
        <div className="fadeIn rounded-[6px] relative px-[8px] py-[8px] group cursor-pointer text-center flex items-center justify-center">
          <div className="flex justify-center items-center text-white">
            Loading...{" "}
            <div className="w-8 h-8 border-4 border-blue-500 rounded-full animate-spin"></div>
          </div>
        </div>
      ) : (
        <div
          onClick={handleClick}
          className="fadeIn rounded-[6px] relative px-[8px] py-[8px] bg-[#7edeff] group hover:bg-slate-300 cursor-pointer text-center duration-300 flex items-center justify-center"
        >
          <p className="text-[12px] font-semibold text-[#7629f8] group-active:text-black group-hover:text-black">
            {" "}
            {label}
          </p>
        </div>
      )}
    </>
  );
};

export default Button;
