import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DataContext from "../context/DataContext";

export default function NavBar() {
  const [page, setPage] = useState("/Home");
  const navRef = useRef<any>();
  const navigate = useNavigate();
  const location = useLocation();

  const dataContext = useContext(DataContext);

  const [activeTab, setActiveTab] = useState("/");

  const handleTabClick = (tab: string) => {
    navigate(tab);
    setActiveTab(tab);
  };

  return (
    <>
      <footer className="fixed bottom-0 left-0 right-0 bg-[#2d30d2] bg-opacity-80 text-[#b9b114] border-t-4 border-t-indigo-500 font-bold">
        <nav className="flex justify-around py-2">
          <div
            className={`flex flex-col items-center transition-colors duration-300 ${
              activeTab === "/" ? "text-[#ffffff]" : ""
            }`}
            onClick={() => handleTabClick("/")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
              />
            </svg>
            <span className="text-sm mt-1">Home</span>
          </div>
          <div
            className={`flex flex-col items-center transition-colors duration-300 ${
              activeTab === "/game" ? "text-[#ffffff]" : ""
            }`}
            onClick={() => handleTabClick("/game")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
            <span className="text-sm mt-1">Game</span>
          </div>
          <div
            className={`flex flex-col items-center transition-colors duration-300 ${
              activeTab === "/profile" ? "text-[#ffffff]" : ""
            }`}
            onClick={() => handleTabClick("/profile")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <span className="text-sm mt-1">Profile</span>
          </div>
        </nav>
      </footer>
    </>
  );
}
